/*! _error-elements.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Error pages SCSS file
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Error page layout
1. Media Queries
=============================================================================
***/

/* ==========================================================================
0. Error page layout
========================================================================== */

.error-wrap {
    position: relative;

    &.error-centered {
        .bg-404 {
            position: absolute;
            top: 40%;
            left: 0;
            right: 0;
            margin: 0 auto;
            font-size: 25rem;
            font-weight: 700;
            color: rgba($blue-grey, 0.15);
            transform: translateY(-50%);
            z-index: 0;
        }

        img {
            position: relative;
            display: block;
            margin: 0 auto;
            max-width: 660px;
            z-index: 1;
        }

        .error-caption {
            h2 {
                color: $blue-grey;
                font-family: $font-main;
                font-size: 2rem;
                max-width: 560px;
                margin: 0 auto;
            }

            p {
                color: $muted-grey;
                font-size: 1.2rem;
            }

            .button-wrap {
                margin: 20px 0;
            }
        }
    }
}

/* ==========================================================================
1. Media Queries
========================================================================== */

@media only screen and (max-width: 767px) {
    .error-wrap {
        &.error-centered {
            .bg-404 {
                top: 28%;
                font-size: 11rem;
            }
        }
    }
}
