/*! _pages-demo-landing.scss | Bulkit | CSS Ninja */

/* ==========================================================================
DEMO landing styles (DO NOT USE IN PRODUCTION)
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Hero
1. Subpage Hero
2. Titles
3. Sections
4. Boxed features
5. CTA block
6. Alternate section
7. Light medium footer (Demo)
8. Media Queries
9. Component pages layout and elements
10. Flex card demo styles
11. Code accordion
12. Demo Icons
13. Navbar styles switcher
14. Bulkit color cards
15. Toast messages
16. Grid system demo
17. Parallax
18. Tags
19. Footer
20. Code highlight
21. Demo media queries
=============================================================================
***/

/* ==========================================================================
0. Hero
========================================================================== */

@keyframes gradientBG {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

//Canvas
#demo-canvas, #bubbled-canvas {
    position: absolute;
    bottom: 20%;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.hero {
    &.rounded-hero {
        position: relative;
        z-index: 3;

        &.is-active {
            .absolute-header {
                transform: translateY(0);

                .header-inner {
                    .states {
                        opacity: 1;
                        transform: translateY(-50%) scale(1);
                    }
                }
            }

            .hero-body {
                .hero-mockup {
                    transform: translateY(0);
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }

        .hero-body {
            position: relative;
            z-index: 1;

            .tag {
                max-height: 18px;
                font-weight: 500;
            }

            .hero-mockup {
                display: block;
                max-width: 740px;
                margin: 0 auto;
                transform: translateY(10px);
                opacity: 0;
                border-radius: 12px;
                box-shadow: $light-box-shadow;
                pointer-events: none;
                transition: all .4s;
                transition-delay: .3s;
            }

            .buttons {
                display: flex;
                justify-content: center;
            }
        }
    }
}

.is-hero-title {
    z-index: 2;

    h1, h2 {
        color: $white;
    }
}

.absolute-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 580px;
    background: $secondary;
    background: linear-gradient(-45deg, $accent, $secondary, $secondary, $accent);
    transform: translateY(-100%);
    transition: all .3s;

    .header-inner {
        position: relative;
        height: 100%;
        width: 100%;

        .cut-circle {
            display: block;
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: auto;
        }
    }
}

/* ==========================================================================
1. Subpage Hero
========================================================================== */

.sub-hero {
    background: #f2f2fa;

    &.has-shapes {
        .sub-hero-container {
            .sub-hero-content {
                overflow: hidden;
                position: relative;
                box-shadow: $primary-box-shadow;

                .square {
                    position: absolute;

                    &.square-1 {
                        top: -20px;
                        left: -16px;
                        transform: rotate(124deg);
                        height: 68px;
                        width: 68px;
                        border-radius: 12px;
                        background: darken($primary, 6%);
                    }

                    &.square-2 {
                        bottom: 20px;
                        right: -16px;
                        transform: rotate(194deg);
                        height: 88px;
                        width: 88px;
                        border-radius: 16px;
                        background: darken($primary, 6%);
                    }

                    &.square-3 {
                        top: -90px;
                        right: 35%;
                        transform: rotate(234deg);
                        height: 160px;
                        width: 160px;
                        border-radius: 26px;
                        background: darken($primary, 6%);
                    }

                    &.square-4 {
                        top: 50%;
                        left: 15%;
                        transform: rotate(32deg);
                        height: 48px;
                        width: 48px;
                        border-radius: 8px;
                        background: darken($primary, 6%);
                    }

                    &.square-5 {
                        top: 30%;
                        left: 25%;
                        transform: rotate(60deg);
                        height: 18px;
                        width: 18px;
                        border-radius: 6px;
                        border: 2px solid lighten($primary, 16%);
                    }

                    &.square-6 {
                        top: 28%;
                        left: 32%;
                        transform: rotate(120deg);
                        height: 32px;
                        width: 32px;
                        border-radius: 6px;
                        border: 2px solid lighten($primary, 16%);
                    }

                    &.square-7 {
                        top: 24%;
                        left: 27%;
                        transform: rotate(60deg);
                        height: 10px;
                        width: 10px;
                        border-radius: 3px;
                        border: 1.4px solid lighten($primary, 16%);
                    }
                }
            }
        }
    }

    .hero-body {
        &.is-narrow {
            padding-top: 20px;
        }
    }

    .sub-hero-container {
        position: relative;
        display: flex;
        border-radius: 12px;
        background: $primary;

        .sub-hero-content {
            text-align: center;
            width: 100%;
            padding: 4rem;
            border-radius: 12px;

            h1, h3 {
                position: relative;
                z-index: 10;
            }
        }

        .sub-hero-search {
            position: absolute;
            bottom: -18px;
            left: 0;
            right: 0;
            width: 100%;
            max-width: 640px;
            margin: 0 auto;
            box-shadow: $light-box-shadow;
            background: $white;
            padding: 10px;
            border-radius: 10px;

            .search-inner {
                display: flex;

                .control {
                    flex-grow: 2;
                    margin-right: 12px;

                    &:first-child {
                        border-right: 1px solid darken($fade-grey, 3%);
                    }

                    .input {
                        border: none;
                        min-height: 46px;
                        max-height: 46px;
                        padding-left: 46px;
                    }

                    .icon {
                        height: 46px;

                        i {
                            font-size: 18px;
                            transition: color .3s;
                        }
                    }
                }

                .button {
                    min-height: 46px;
                    max-height: 46px;
                    min-width: 120px;
                    font-weight: 600;
                }
            }
        }
    }
}

/* ==========================================================================
2. Titles
========================================================================== */

.title {
    font-family: $font-main;
    color: $blue-grey;

    &.is-bigger {
        font-size: 4rem;
    }

    &.is-medium {
        font-size: 4rem;
    }

    &.is-light {
        color: $white;
    }
}

.subtitle {
    &.is-light {
        color: $white;
    }
}

/* ==========================================================================
3. Sections
========================================================================== */

.section {
    position: relative;

    .section-title {
        h2 {
            margin-bottom: 6px;
        }

        h4 {
            color: $muted-grey;
            font-size: 1.4rem;
        }
    }

    .section-feature-description {
        font-size: 1.2rem;
    }
}

.has-background-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &.is-contain {
        background-size: contain;
        background-repeat: repeat;
    }
}

/* ==========================================================================
4. Boxed features
========================================================================== */

.stacks {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 540px;
    margin: 0 auto;
    padding: 16px 0 30px 0;

    .stack {
        height: 54px;
        width: 54px;
        min-width: 54px;
        border-radius: 50%;
        background: $white;
        border: 1px solid $fade-grey;
        box-shadow: $light-box-shadow;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 6px;

        img {
            display: block;
            height: 40px;
            width: 40px;
        }
    }
}

.boxed-features {
    padding: 40px 0;

    .flex-card {
        padding: 30px;
        display: flex;
        flex-wrap: wrap;

        .flex-cell {
            width: 25%;
            border-right: 1px solid darken($fade-grey, 3%);
            text-align: center;
            padding: 24px 16px;

            &.is-bordered {
                border-bottom: 1px solid darken($fade-grey, 3%);
            }

            &.no-border-edge {
                border-right: none;
            }

            i {
                font-size: 2.2rem;
                color: $secondary;
            }

            h3 {
                font-weight: 500;
                color: $blue-grey;
            }

            p {
                font-size: .95rem;
                color: $muted-grey;
                max-width: 200px;
                margin: 0 auto;
            }
        }
    }
}

.demo-section {
    margin-bottom: 100px;

    .demo-section-title {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        img {
            display: block;
            width: 100%;
            max-width: 100px;
        }

        .title-meta {
            margin-left: 12px;
            line-height: 1.2;

            h3 {
                font-family: $font-main;
                font-weight: 500;
                font-size: 1.3rem;
                color: $blue-grey;
            }

            p {
                font-family: $font-text;
                font-size: 1.1rem;
                color: $blue-grey-light;
            }
        }
    }
}

.demo-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: $light-box-shadow;

    &:hover {
        .circle-overlay {
            transform: scale(35);
        }

        .demo-title {
            opacity: 1;
            transform: translateY(0);
            transition-delay: 0 !important;
        }

        .demo-link {
            color: $white;
            opacity: 1;
            transform: translateY(0);
            transition-delay: 0 !important;
        }
    }

    .new-tag {
        position: absolute;
        bottom: 10px;
        right: 10px;
        background: red;
        color: $white;
        font-family: $font-text;
        padding: 6px 16px;
        border-radius: 8px;
        text-transform: uppercase;
        font-size: .8rem;
        z-index: 8;
    }

    img {
        border-radius: 10px;
        display: block;
    }

    .circle-overlay {
        position: absolute;
        top: -60px;
        left: -60px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: $secondary;
        transform: scale(1);
        transition: all 0.5s;
        z-index: 1;
    }

    .demo-info {
        position: absolute;
        margin: 0 auto;
        top: 35%;
        left: 0;
        right: 0;
        z-index: 2;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .demo-title {
        color: $white;
        font-family: $font-main;
        font-size: 1.5rem;
        font-weight: 600;
        padding-bottom: 0;
        opacity: 0;
        transform: translateY(20px);
        transition: all 0.3s;
        transition-delay: 0.1s;
    }

    .demo-link {
        opacity: 0;
        transform: translateY(20px);
        transition: all 0.3s;
        transition-delay: 0.2s;

        a {
            color: $white;
            font-size: 1rem;

            i {
                position: relative;
                top: 8px;
                opacity: 0;
                transform: translateX(0);
                transition: all 0.3s ease-out;
            }

            &:hover i {
                opacity: 1;
                transform: translateX(10px);
            }
        }
    }
}

/* ==========================================================================
5. CTA block
========================================================================== */

.cta-block {
    padding-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid darken($fade-grey, 4%);

    &.no-border {
        padding-bottom: 2rem;
        border-bottom: none;
    }

    &.is-box {
        padding: 3rem 2rem;
        background: $white;
        border-radius: 8px;
        box-shadow: $light-box-shadow;
        border: 1px solid $fade-grey;
        justify-content: space-between;
    }

    .head-text {
        h3 {
            font-family: $font-main;
            font-size: 1.8rem;
            color: $blue-grey;
        }

        p {
            font-size: 1.1rem;
            color: $muted-grey;
        }
    }

    .head-action {
        .buttons {
            .button {
                &.action-button {
                    height: 36px;
                    min-width: 140px;
                }

                &.chat-button {
                    background: transparent;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    color: $primary;
                    font-weight: 500;

                    &.is-secondary {
                        color: $secondary;
                    }
                }
            }
        }
    }
}

//Component boxes
.component-box {
    display: block;
    text-align: center;
    background: $white;
    border: 1px solid darken($fade-grey, 3%);
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 0;
    transition: all .3s;
    cursor: pointer;

    &:hover {
        transform: translateY(-5px);
        box-shadow: $light-box-shadow;
        border-color: $secondary;

        .component {
            img {
                filter: grayscale(0);
                opacity: 1;
            }
        }

        .component-title {
            color: $blue-grey;
        }
    }

    .component {
        img {
            display: block;
            filter: grayscale(1);
            opacity: .6;
            max-height: 60px;
            transition: all .3s;
        }
    }

    .component-title {
        font-size: 1.1rem;
        padding-top: 6px;
        color: $muted-grey;
        transition: color .3s;
    }
}

/* ==========================================================================
6. Alternate section
========================================================================== */

.section-focus {
    background: #f2f2fa;
}

/* ==========================================================================
7. Light medium footer
========================================================================== */
.footer-light-medium-demo {
    padding-bottom: 0 !important;
    padding-top: 4rem !important;
    background: $light-white;

    .footer-head {
        padding-bottom: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid darken($fade-grey, 4%);

        .head-text {
            h3 {
                font-family: $font-main;
                font-size: 1.8rem;
                color: $blue-grey;
            }

            p {
                font-size: 1.1rem;
                color: $muted-grey;
            }
        }

        .head-action {
            .buttons {
                .button {
                    &.action-button {
                        height: 36px;
                        min-width: 140px;
                    }

                    &.chat-button {
                        background: transparent;
                        border: none;
                        outline: none;
                        box-shadow: none;
                        color: $primary;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .footer-body {
        padding-top: 3rem;

        .footer-column {
            padding-top: 20px;

            .column-header {
                font-family: $font-sub;
                text-transform: uppercase;
                color: $blue-grey;
                font-size: 1rem;
                font-weight: 700;
                margin: 10px 0;
            }

            .column-item {
                padding-bottom: 10px;

                a {
                    color: $title-grey;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }

        .social-links {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            a {
                color: $title-grey;
                margin: 0 5px;

                &:hover {
                    color: $primary;
                }
            }
        }

        .footer-description {
            color: $title-grey;
        }

        .moto {
            color: $muted-grey;
        }

        .small-footer-logo {
            height: 24px;
        }
    }

    .footer-copyright {
        color: $muted-grey;
        padding: 4rem 0 2rem 0;

        a {
            color: $muted-grey;

            &:hover {
                color: $primary;
            }
        }
    }
}

@media (max-width: 767px) {
    .footer-light-medium-demo {
        .footer-head {
            flex-direction: column;
            text-align: center;

            .head-text {
                padding-bottom: 20px;
            }
        }
    }
}

/* ==========================================================================
11. Media Queries
========================================================================== */

@media (max-width: 767px) {
    //Landing pages
    .main-hero {
        .title {
            &.is-bigger {
                font-size: 3rem !important;
            }
        }
    }

    .rounded-hero {
        min-height: 75vh !important;
        max-height: 75vh;

        .absolute-header {
            height: 500px;
        }

        .hero-body {
            .hero-mockup {
                max-width: 340px !important;
            }
        }
    }

    .sub-hero {
        .sub-hero-container {
            flex-direction: column;

            .sub-hero-content {
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 0;

                .square {
                    &.square-3, &.square-5, &.square-6, &.square-7 {
                        display: none;
                    }
                }
            }

            .sub-hero-search {
                position: relative;
                top: 0;
                width: calc(100% - 40px);
                margin: 0 20px 20px 20px;

                .search-inner {
                    flex-direction: column;
                }
            }
        }
    }

    .boxed-features {
        .flex-card {
            .flex-cell {
                width: 100%;
                border-right: none;
                border-bottom: 1px solid darken($fade-grey, 3%);

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    .cta-block {
        flex-direction: column;
        text-align: center;

        .head-text {
            padding-bottom: 20px;
        }
    }

    .app-wrapper {
        .mockup-wrapper {
            padding: 10px;
            margin-top: 30px;
        }

        .app-features {
            .app-feature {
                h3 {
                    margin-left: 0;
                }
            }
        }
    }

    .carousel-wrap {
        .testimonials-solo-carousel {
            .testimonial-item {
                flex-direction: column;

                .left-side, .right-side {
                    width: 100%;
                }

                .right-side {
                    padding: 0;

                    .testimonial-content {
                        .learn-more {
                            margin-bottom: 20px;
                        }

                        .metrics {
                            .metric {
                                flex-direction: column;
                                width: 50%;
                                margin: 0 auto;

                                .meta {
                                    margin: 0;
                                    padding-top: 12px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .grid-clients.three-grid {
        .columns {
            display: flex;
        }
    }

    .job-results-wrapper {
        padding-top: 0;
        padding-bottom: 0;

        .job-results-section {
            h3 {
                text-align: center;
            }

            .result-list {
                .result-list-item {
                    flex-direction: column;
                    text-align: center;
                    border: 1px solid $fade-grey;
                    border-radius: 8px;
                    margin-bottom: 20px;

                    .end {
                        margin: 10px auto 0 auto;
                    }
                }
            }
        }
    }

    .footer-light-medium-demo {
        .footer-body {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    //Landing pages
    .main-hero {
        .is-hero-title {
            text-align: center !important;

            .buttons {
                justify-content: center;
            }
        }
    }

    .rounded-hero {
        min-height: 75vh !important;

        .hero-body {
            .hero-mockup {
                max-width: 610px !important;
            }
        }
    }

    .section {
        .featured-image {
            max-width: 540px !important;
            margin: 0 auto;
        }

        .section-title.is-demo, .section-feature-description {
            max-width: 460px !important;
            margin: 0 auto !important;
        }
    }

    //Presnetation tools
    .presentation-tools {
        display: flex;

        .content-flex {
            flex-direction: column;
            text-align: center;

            > div:nth-child(2) {
                margin-left: 0 !important;
                text-align: center !important;
            }
        }
    }

    //Clients
    .grid-clients.three-grid {
        .columns {
            display: flex;
        }
    }

    //Stories
    .stories {
        display: flex;

        .column {
            min-width: 50%;
        }
    }

    //Helpers
    .is-centered-tablet-portrait {
        text-align: center;

        img {
            max-width: 380px;
            margin: 0 auto 30px auto;
            display: block;
        }

        p {
            max-width: 380px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .is-flex-portrait {
        display: flex;
    }

    .footer-light-medium-demo {
        .footer-head, .footer-body {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}


/* ==========================================================================
9. Component pages layout and elements
========================================================================== */

//Components section hero title
.components-title, .handwritten-title, .handwritten-text {
    font-family: 'Nexa Bold', cursive;

    &.is-cta {
        font-size: 3.4rem !important;
    }
}

.components-title {
    margin-bottom: 0 !important;
}

//Components section hero subtitle
.components-subtitle {
    margin-bottom: 0 !important;
    padding: 20px 0;
    font-family: $font-sub;
}

//Component info
.info-block {
    position: relative;
    background: $white;
    padding: 30px;
    border: 1px solid darken($fade-grey, 3%);
    border-radius: 8px;
    margin: 0 auto 30px auto;
    max-width: 480px;
    box-shadow: $light-box-shadow;

    .tag {
        position: absolute;
        top: 16px;
        right: 16px;
        height: 24px;

        &.is-secondary {
            box-shadow: $secondary-box-shadow;
        }

        &.is-success {
            box-shadow: $success-box-shadow;
        }
    }

    h4 {
        font-size: 1.4rem;
        color: $blue-grey;
        font-family: $font-main;
    }

    p {
        padding: 10px 0;
    }
}

.component-block {
    max-width: 480px;
    margin: 0 auto;

    &.is-large {
        max-width: 640px;
    }
}

//Spacer
.spacer-40 {
    margin-bottom: 40px;
}

//Hero heading
.hero-heading {
    font-size: 3rem !important;
    font-family: Open Sans, sans-serif !important;

    + h2 {
        padding: 0;
        margin-bottom: 0 !important;
    }
}

//Demo section title adjustment
.section-title.is-demo {
    font-weight: 700;
    font-size: 2rem !important;
}

//Components hero svg
.vivus-svg {
    max-height: 450px;
    width: 450px;
}

//Feature icon
.feature-icon {
    height: 140px !important;
}

.feature-icon-md {
    height: 110px !important;
}

.feature-icon-sm {
    height: 90px !important;
}

//Feature description
.feature-description {
    font-size: 1.2rem;
    color: $title-grey;

    span {
        display: block;

        &.feature-key {
            font-size: 1.2rem;
            font-weight: 600;
            color: $secondary;
            font-family: $font-sub;
        }
    }
}

//Code highlight
.highlight {
    padding: 1.4rem;
    overflow: auto;
    background: #444F60;
    border-radius: 4px;
}

.highlight::-webkit-scrollbar {
    width: 10px;
}

.highlight::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
}

code.hljs span {
    font-family: Inconsolata, monospace !important;
}

//Media queries (mobile)
@media (max-width: 767px) {
    .components-title, .components-subtitle, .components-cta {
        text-align: center !important;
    }
}

/* ==========================================================================
10. Flex card demo styles
========================================================================== */
.flex-card {
    &.is-active {
        .name {
            color: $primary !important;
        }
    }

    .card-avatar img {
        width: 45px;
        border-radius: 100px;
    }

    .card-name {
        margin-left: 15px;

        .name {
            color: $blue-grey;
        }

        .position {
            font-size: 0.9rem;
            color: $title-grey;
        }
    }

    .card-select i {
        color: $placeholder;
        border: 2px dashed $placeholder;
        border-radius: 100px;
        padding: 6px;
        font-size: 20px;
        transition: all 0.5s;

        &:hover, &.is-active {
            color: $primary;
            border: 2px solid $primary;
        }
    }

    .title-bold {
        font-family: $font-main;
    }

    .title-light {
        font-family: $font-sub;
    }
}

//Feature cards
.feature-card {
    padding: 20px !important;

    img {
        margin: 0 !important;
    }
}

/* ==========================================================================
11. Code accordion
========================================================================== */
.accordion-wrapper {
    .toggle-wrap {
        border-bottom: 1px solid #e0e0e0;

        .trigger a {
            display: block;
            cursor: pointer;
            position: relative;
            padding: 0;
            line-height: 26px;
            outline: none;
            color: $blue-grey;
            font-size: 14px;
            background-color: #fff;
            border: 1px solid #e0e0e0;
            transition: background-color 0.2s, color 0.2s;
            padding: 16px 27px;
            margin: -1px 0 0 0;

            i {
                padding-right: 6px;
                margin: 0 4px 0 0;
                transition: all 0.3s;
            }

            .im-icon-Add {
                position: absolute;
                right: 0;
                top: 55%;
                font-size: 24px;
                transform: translate3d(0, -50%, 0) rotate(0);
                transition: 0.2s;
            }

            .im-icon-Coding {
                position: relative;
                top: 7px;
            }
        }

        .trigger a {
            text-transform: none;
            border: none;
            margin: 0;
            padding: 15px 0;
            text-transform: none;
            font-size: 11px;
            font-weight: 500;
            background-color: transparent;
            color: $accent;
            padding-right: 60px;
            font-weight: 400;
        }

        .trigger.active .im-icon-Add {
            margin-top: 2px;
            transform: translate3d(0, -50%, 0) rotate(45deg);
            color: $accent;
        }

        .toggle-container {
            //padding: 0px 0 30px 0;
            border: none;
        }
    }
}

/* ==========================================================================
12. Demo Icons
========================================================================== */

//Icons mind collection item
.demo-im-block {
    &:hover {
        background: $light-grey;

        .demo-im i {
            transform: scale(1.1);
            color: $accent;
        }
    }

    .demo-im i {
        font-size: 2.6rem;
        margin: 0 15px 0 10px;
        color: $basaltic-grey;
        transition: all 0.4s;
    }

    .im-code {
        font-size: 1rem;

        code {
            color: $basaltic-grey;
        }
    }
}

//Font Awesome collection item
.demo-fa-block {
    padding: 10px 5px;

    &:hover {
        background: $light-grey;

        .demo-fa i {
            transform: scale(1.1);
            color: $accent;
        }
    }

    .demo-fa i {
        font-size: 1.6rem;
        margin: 0 15px 0 10px;
        color: $basaltic-grey;
        transition: all 0.4s;
    }

    .fa-embed-code {
        font-size: 1rem;

        code {
            color: $basaltic-grey;
        }
    }
}

//Simple Line Icons collection item
.demo-sl-block {
    padding: 10px 5px;

    &:hover {
        background: $light-grey;

        .demo-sl i {
            transform: scale(1.1);
            color: $accent;
        }
    }

    .demo-sl i {
        font-size: 1.6rem;
        margin: 0 15px 0 10px;
        color: $basaltic-grey;
        transition: all 0.4s;
    }

    .sl-embed-code {
        font-size: 1rem;

        code {
            color: $basaltic-grey;
        }
    }
}

/* ==========================================================================
13. Navbar styles switcher
========================================================================== */

//Navbar inverse selector
.nav-switch {
    padding: 20px;
    position: fixed;
    right: 40px;
    bottom: 40px;
    background: lighten($sidebar, 5%);
    border: 1px solid $fade-grey;
    border-radius: 4px;
    min-width: 220px;

    .panel-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            text-transform: uppercase;
            color: $white;
        }
    }

    .panel-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        .color-dot {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 1px solid $fade-grey;
            margin-top: 10px;
            cursor: pointer;

            &.is-white {
                background: $white;
            }

            &.is-blue {
                background: $blue;
            }

            &.is-purple {
                background: $secondary;
            }

            &.is-red {
                background: $red;
            }

            &.is-reverse {
                background: $sidebar;
            }
        }
    }
}

/* ==========================================================================
14. Bulkit color cards
========================================================================== */
.flex-card {
    &.color-card {
        .color-item {
            display: flex;
            align-items: center;

            .color-spot {
                display: block;
                width: 40px !important;
                height: 40px !important;
                border: 1px solid $fade-grey;
                border-radius: 100px;

                &.is-white {
                    background: $white;
                }

                &.is-smoke-white {
                    background: $smoke-white;
                }

                &.is-grey-white {
                    background: $grey-white;
                }

                &.is-grey-white-accent {
                    background: $grey-white-accent;
                }

                &.is-light-grey {
                    background: $light-grey;
                }

                &.is-ultra-light-grey {
                    background: $ultra-light-grey;
                }

                &.is-title-grey {
                    background: $title-grey;
                }

                &.is-blue-grey {
                    background: $blue-grey;
                }

                &.is-light-blue-grey {
                    background: $light-blue-grey;
                }

                &.is-medium-grey {
                    background: $medium-grey;
                }

                &.is-basaltic-grey {
                    background: $basaltic-grey;
                }

                &.is-muted-grey {
                    background: $muted-grey;
                }

                &.is-section-grey {
                    background: $section-grey;
                }

                &.is-section-grey-accent {
                    background: $section-grey-accent;
                }

                &.is-header-grey {
                    background: $header-grey;
                }

                &.is-default-input {
                    background: $default-input;
                }

                &.is-placeholder {
                    background: $placeholder;
                }

                &.is-fade-grey {
                    background: $fade-grey;
                }

                &.is-sidebar {
                    background: $sidebar;
                }

                &.is-coal {
                    background: $coal;
                }

                &.is-grey-3 {
                    background: $grey-3;
                }

                &.is-grey-5 {
                    background: $grey-5;
                }

                &.is-grey-6 {
                    background: $grey-6;
                }

                &.is-cream-grey {
                    background: $cream-grey;
                }

                &.is-icon-grey {
                    background: $icon-grey;
                }

                &.is-blue {
                    background: $blue;
                }

                &.is-green {
                    background: $green;
                }

                &.is-orange {
                    background: $orange;
                }

                &.is-red {
                    background: $red;
                }

                &.is-facebook {
                    background: $facebook;
                }

                &.is-twitter {
                    background: $twitter;
                }

                &.is-linkedin {
                    background: $linkedin;
                }

                &.is-tumblr {
                    background: $tumblr;
                }

                &.is-reddit {
                    background: $reddit;
                }

                &.is-github {
                    background: $github;
                }

                &.is-dribbble {
                    background: $dribbble;
                }

                &.is-youtube {
                    background: $youtube;
                }

                &.is-google-plus {
                    background: $google-plus;
                }

                &.is-primary {
                    background: $primary;
                }

                &.is-secondary {
                    background: $secondary;
                }

                &.is-accent {
                    background: $accent;
                }

                &.is-green-accent {
                    background: #06f4b6;
                }

                &.is-deep-primary {
                    background: #0082c8;
                }

                &.is-deep-secondary {
                    background: #7c4dff;
                }

                &.is-deep-accent {
                    background: #00b289;
                }

                &.is-lemonade-primary {
                    background: #039BE5;
                }

                &.is-lemonade-secondary {
                    background: #0084c4;
                }

                &.is-lemonade-accent {
                    background: #00D1B2;
                }

                &.is-dashboard-primary {
                    background: #00D1B2;
                }

                &.is-dashboard-secondary {
                    background: #7F00FF;
                }

                &.is-dashboard-accent {
                    background: #536dfe;
                }
            }

            .color-values {
                margin: 0 20px;

                .hex-value {
                    color: $muted-grey;

                    span {
                        font-weight: 600;
                        color: $blue-grey;
                    }
                }

                .variable-value span {
                    color: $accent;
                    font-weight: 600;
                }
            }
        }
    }
}

/* ==========================================================================
15. Toast messages
========================================================================== */
.iziToast-cover {
    width: 60px !important;
    background-size: cover !important;
}

/* ==========================================================================
16. Grid system demo
========================================================================== */
.grid-system {
    .columns {
        .column p {
            padding: 40px;
            font-size: 1.2rem;
            font-weight: 600;
            color: $white;
            text-align: center;
            border-radius: 4px;

            &.is-blue {
                background: $blue;
            }

            &.is-green {
                background: $green;
            }

            &.is-orange {
                background: $orange;
            }

            &.is-red {
                background: $red;
            }
        }
    }

    &.is-medium {
        .column p {
            padding: 25px;
        }
    }
}

/* ==========================================================================
17. Parallax
========================================================================== */

//Parallax hero title
.parallax-hero-title {
    font-size: 6rem !important;
    font-family: 'Nexa Bold', cursive  !important;

    + h2 {
        padding: 0 20%;
        margin-bottom: 0 !important;
    }
}

/* ==========================================================================
18. Tags
========================================================================== */
.tag {
    &.is-bold {
        border-width: 2px !important;
        font-weight: 700 !important;
    }
}

/* ==========================================================================
19. Footer
========================================================================== */

//Small footer logo
.small-footer-logo {
    height: 38px;
}

//More info
span.more-info {
    font-size: 22px;
}

//More info email
span.more-info-email {
    font-size: 26px;
    margin-top: 20px;
    color: $primary;
}

/* ==========================================================================
20. Code highlight
========================================================================== */

pre {
    background-color: transparent !important;
    color: unset;
    font-size: 0.875em;
    overflow-x: auto;
    padding: 0 !important;
    white-space: pre;
    word-wrap: normal;
}

.hljs {
    display: block;
    overflow-x: auto;
    padding: 1.5em;
    background: #444F60;
    color: white;
}

.hljs-name,
.hljs-strong {
    font-weight: bold;
    color: #4FC1EA;
}

.hljs-code,
.hljs-emphasis {
    font-style: italic;
}

.hljs-tag {
    color: #909090;
}

.hljs-variable,
.hljs-template-variable,
.hljs-selector-id,
.hljs-selector-class {
    color: #ade5fc;
}

.hljs-string,
.hljs-bullet {
    color: #F39200 !important;
}

.hljs-type,
.hljs-title,
.hljs-section,
.hljs-attribute,
.hljs-quote,
.hljs-built_in,
.hljs-builtin-name {
    color: #ffa;
}

.hljs-attr {
    color: #68BB88;
}

.hljs-number,
.hljs-symbol,
.hljs-bullet {
    color: #d36363;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-literal {
    color: #fcc28c;
}

.hljs-comment,
.hljs-deletion,
.hljs-code {
    color: #888;
}

.hljs-regexp,
.hljs-link {
    color: #c6b4f0;
}

.hljs-meta {
    color: #fc9b9b;
}

.hljs-deletion {
    background-color: #fc9b9b;
    color: #333;
}

.hljs-addition {
    background-color: #a2fca2;
    color: #333;
}

.hljs a {
    color: inherit;
}

.hljs a:focus,
.hljs a:hover {
    color: inherit;
    text-decoration: underline;
}


/* ==========================================================================
21. Demo Media queries
========================================================================== */

//Mobile
@media (max-width: 767px) {
    #main-landing-title {
        top: -60px;

        h1 {
            &:after {
                right: 3px;
            }
        }
    }

    #main-landing-mockup {
        margin-top: -16rem;
    }

    .parallax-hero-title {
        font-size: 4rem !important;

        + h2 {
            padding: 0 8%;
            margin-bottom: 0 !important;
        }
    }
}

//Tablet orientation portrait
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    #main-landing-mockup {
        margin-top: -28rem;
    }
}

//Tablet orientation Landscape
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    #main-landing-title {
        h1 {
            &:after {
                right: 3px;
            }
        }
    }
}