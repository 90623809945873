/*! _labels.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Badges
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Badge
1. Tags
=============================================================================
***/

/* ==========================================================================
0. Badge
========================================================================== */

.b-badge {
    height: 20px;
    min-width: 20px;
    max-width: 100px;
    padding: 3px 6px;
    line-height: 1.2;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 0.8rem;
    color: $white;
    background: $muted-grey;
    text-align: center;
    border-radius: 3px;

    &.rounded {
        border-radius: 200px;
    }

    &.badge-outlined {
        background: transparent;
        border: 1px solid $muted-grey;
        color: $muted-grey;
    }

    &.is-primary {
        background: $primary;

        &.badge-outlined {
            background: transparent;
            border: 1px solid $primary;
            color: $primary;
        }
    }

    &.is-secondary {
        background: $secondary;

        &.badge-outlined {
            background: transparent;
            border: 1px solid $secondary;
            color: $secondary;
        }
    }

    &.is-accent {
        background: $accent;

        &.badge-outlined {
            background: transparent;
            border: 1px solid $accent;
            color: $accent;
        }
    }

    &.is-success {
        background: $green;

        &.badge-outlined {
            background: transparent;
            border: 1px solid $green;
            color: $green;
        }
    }

    &.is-warning {
        background: $orange;

        &.badge-outlined {
            background: transparent;
            border: 1px solid $orange;
            color: $orange;
        }
    }

    &.is-danger {
        background: $red;

        &.badge-outlined {
            background: transparent;
            border: 1px solid $red;
            color: $red;
        }
    }

    &.is-info {
        background: $blue;

        &.badge-outlined {
            background: transparent;
            border: 1px solid $blue;
            color: $blue;
        }
    }
}

/* ==========================================================================
1. Tags
========================================================================== */

.tag {
    margin-bottom: 5px;
    font-family: $font-text;

    &.squared {
        border-radius: 2px !important;
    }

    &.is-outlined {
        border: 1px solid $muted-grey;
    }

    &.is-primary {
        background: $primary;
        color: $white;

        &.is-outlined {
            background: transparent;
            border: 1px solid $primary;
            color: $primary;
        }
    }

    &.is-secondary {
        background: $secondary;
        color: $white;

        &.is-outlined {
            background: transparent;
            border: 1px solid $secondary;
            color: $secondary;
        }
    }

    &.is-accent {
        background: $accent;
        color: $white;

        &.is-outlined {
            background: transparent;
            border: 1px solid $accent;
            color: $accent;
        }
    }

    &.is-success {
        background: $green;
        color: $white;

        &.is-outlined {
            background: transparent;
            border: 1px solid $green;
            color: $green;
        }

        &.is-pastel {
            background: lighten($success, 48%);
            color: darken($success, 8%);
        }
    }

    &.is-warning {
        background: $orange !important;
        color: $white !important;

        &.is-outlined {
            background: transparent !important;
            border: 1px solid $orange !important;
            color: $orange !important;
        }

        &.is-pastel {
            background: lighten($warning, 40%) !important;
            color: darken($warning, 8%) !important;
        }
    }

    &.is-danger {
        background: $red;
        color: $white;

        &.is-outlined {
            background: transparent;
            border: 1px solid $red;
            color: $red;
        }

        &.is-pastel {
            background: lighten($danger, 44%) !important;
            color: darken($danger, 8%) !important;
        }
    }

    &.is-info {
        background: $blue;
        color: $white;

        &.is-outlined {
            background: transparent;
            border: 1px solid $blue;
            color: $blue;
        }

        &.is-pastel {
            background: lighten($info, 44%) !important;
            color: darken($info, 8%) !important;
        }
    }

    &.is-purple {
        background: $purple;
        color: $white;

        &.is-outlined {
            background: transparent;
            border: 1px solid $purple;
            color: $purple;
        }

        &.is-pastel {
            background: lighten($purple, 38%) !important;
            color: darken($purple, 8%) !important;
        }
    }

    &.is-purple {
        background: $purple;
        color: $white;

        &.is-outlined {
            background: transparent;
            border: 1px solid $purple;
            color: $purple;
        }

        &.is-pastel {
            background: lighten($purple, 38%) !important;
            color: darken($purple, 8%) !important;
        }
    }

    &.is-yellow {
        background: $yellow;
        color: $white;

        &.is-outlined {
            background: transparent;
            border: 1px solid $yellow;
            color: $yellow;
        }

        &.is-pastel {
            background: lighten($yellow, 22%) !important;
            color: darken($yellow, 8%) !important;
        }
    }
    
    &.is-orange {
        background: $salmon;
        color: $white;

        &.is-outlined {
            background: transparent;
            border: 1px solid $salmon;
            color: $salmon;
        }

        &.is-pastel {
            background: lighten($salmon, 22%) !important;
            color: darken($salmon, 8%) !important;
        }
    }

    &.is-light {
        background: $white;
        color: $blue-grey;

        &.is-outlined {
            background: transparent;
            border: 1px solid $white;
            color: $white;
        }
    }

    span.delete {
        top: 2px;
    }
}

.tags {
    &.has-addons {
        span:first-child {
            border-bottom-right-radius: 0 !important;
            border-top-right-radius: 0 !important;
            margin-right: 0;
        }
    
        span:last-child {
            border-bottom-left-radius: 0 !important;
            border-top-left-radius: 0 !important;
            margin-left: -5px;
        }
    
        &.is-centered {
            justify-content: center;
        }
    }
}

.control {
    &.tag-group {
        display: flex;
        justify-content: center;
    }
}

.tag {
    &.is-delete {
        margin-left: 1px;
        padding: 0;
        position: relative;
        width: 2em;
        border-radius: 0 2px 2px 0 !important;
        margin-left: -5px;
    
        &:before {
            height: 1px;
            width: 50%;
        }
    
        &:after {
            height: 50%;
            width: 1px;
        }
    
        &:before, &:after {
            background-color: currentColor;
            content: "";
            display: block;
            left: 50%;
            position: absolute;
            top: 50%;
            -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
            -webkit-transform-origin: center center;
            transform-origin: center center;
        }
    }
    
}