/*! _extensions-wallop.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Wallop slider styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Wallop Scale
1. Wallop fade
=============================================================================
***/

/* ==========================================================================
0. Wallop Scale
========================================================================== */

 .Wallop--scale .Wallop-item--hidePrevious,
 .Wallop--scale .Wallop-item--hideNext {
   visibility: visible;
   -webkit-animation: scaleOut 350ms cubic-bezier(0.55, 0.055, 0.675, 0.19) both;
   -moz-animation: scaleOut 350ms cubic-bezier(0.55, 0.055, 0.675, 0.19) both;
   -ms-animation: scaleOut 350ms cubic-bezier(0.55, 0.055, 0.675, 0.19) both;
   animation: scaleOut 350ms cubic-bezier(0.55, 0.055, 0.675, 0.19) both;
 }
 
  .Wallop--scale .Wallop-item--hidePrevious,
  .Wallop--scale .Wallop-item--hideNext { z-index: 2; }
  .Wallop--scale .Wallop-item--showPrevious,
  .Wallop--scale .Wallop-item--showNext { z-index: 1; }
 
 @-webkit-keyframes scaleOut {
   100% {
     -webkit-transform: scale(1.6);
     -moz-transform: scale(1.6);
     -ms-transform: scale(1.6);
     transform: scale(1.6);
     opacity: 0;
   }
 }
 
 @-moz-keyframes scaleOut {
   100% {
     -webkit-transform: scale(1.6);
     -moz-transform: scale(1.6);
     -ms-transform: scale(1.6);
     transform: scale(1.6);
     opacity: 0;
   }
 }
 
 @-ms-keyframes scaleOut {
   100% {
     -webkit-transform: scale(1.6);
     -moz-transform: scale(1.6);
     -ms-transform: scale(1.6);
     transform: scale(1.6);
     opacity: 0;
   }
 }
 
 @keyframes scaleOut {
   100% {
     -webkit-transform: scale(1.6);
     -moz-transform: scale(1.6);
     -ms-transform: scale(1.6);
     transform: scale(1.6);
     opacity: 0;
   }
 }
 
 /* ==========================================================================
 1. Wallop Fade
 ========================================================================== */
 
  .Wallop--fade .Wallop-item--hidePrevious,
  .Wallop--fade .Wallop-item--hideNext {
   visibility: visible;
   -webkit-animation: fadeOut 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
   -moz-animation: fadeOut 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
   -ms-animation: fadeOut 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
   animation: fadeOut 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
 }

 .Wallop--fade .Wallop-item--hidePrevious,
 .Wallop--fade .Wallop-item--hideNext { z-index: 2; }
 .Wallop--fade .Wallop-item--showPrevious,
 .Wallop--fade .Wallop-item--showNext { z-index: 1; }
 
 @-webkit-keyframes fadeOut {
   100% {
     opacity: 0;
     visibility: hidden;
   }
 }
 @-moz-keyframes fadeOut {
   100% {
     opacity: 0;
     visibility: hidden;
   }
 }
 @-ms-keyframes fadeOut {
   100% {
     opacity: 0;
     visibility: hidden;
   }
 }
 @keyframes fadeOut {
   100% {
     opacity: 0;
     visibility: hidden;
   }
 }
 
 
 