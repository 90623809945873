/*! _extensions-iconpicker.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Bulma Iconpicker styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Icon Picker
=============================================================================
***/

/* ==========================================================================
0. Icon Picker
========================================================================== */

.iconpicker-wrapper {
    padding: 20px;
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
    border: 2px solid transparent;
    border-radius: 4px;
    transition: all .3s;

    .help {
        padding-top: 10px;
        font-weight: 500;
    }

    .iconpicker-preview {
        background: $blue;
        transition: all .3s;

        i {
            color: $white;
        }

        &:hover {
            background: $secondary;
        }
    }

    &:hover {
        border: 2px solid $secondary;
    }
}

.iconpicker-modal {
    .iconpicker-icons {
        a {
            color: $muted-grey;

            &:hover {
                color: $secondary;
            }

            &:before {
                content: '' !important;
            }
        }
    }

    &.is-active {
        .modal-background {
            display: block !important;
            width: 100vw !important;
            height: 100vh !important;
            min-width: 100vw !important;
            min-height: 100vh !important;
            position: fixed !important;
            top: 0 !important;
            left: 0 !important;
            background: rgba(0, 0, 0, 0.8) !important;
            transform: scale(1) !important;
            border-radius: 0 !important;
        }

        .modal-card {
            z-index: 99 !important;

            .modal-card-head {
                background: $blue;

                .modal-card-title {
                    color: $white;
                    font-weight: 500;
                }

                .iconpicker-search {
                    background: $white;
                    color: $blue-grey;
                    transition: all .3s;

                    &:focus {
                        outline: none !important;
                    }
                }

                .delete {
                    &:before, &:after {
                        background: $white !important;
                    }
                }
            }

            .iconpicker-sets.tabs {
                ul li {
                    a {
                        border-radius: 0 !important;
                    }

                    &.is-active {
                        a {
                            color: $secondary;
                            border-bottom: 1.4px solid $secondary;
                        }
                    }
                }
            }
        }
    }
}
