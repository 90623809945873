/*! _extensions-calendar.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Bulma Calendar styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Legacy Calendar
1. New Calendar
=============================================================================
***/

/* ==========================================================================
0. Legacy Calendar
========================================================================== */

.calendar-legacy {
    &.is-centered {
        margin: 0 auto;
    }

    &.is-large {
        .calendar-range {
            &:before {
                top: 1.6rem !important;
            }
        }
    }

    &.is-custom {
        border: 1px solid $fade-grey;
        border-radius: 3px;

        .calendar-nav {
            background: $secondary;
        }

        .calendar-nav-previous-month, .calendar-nav-next-month {
            button {
                text-decoration: none !important;

                i {
                    color: $white !important;
                }

                &:hover {
                    background: transparent !important;
                }
            }
        }

        .calendar-body {
            .date-item {
                &.is-today {
                    border-color: $secondary;
                    color: $secondary;
                }

                &.is-active {
                    background: $secondary;
                    color: $white !important;
                    border-color: $secondary;
                }
            }

            .calendar-range {
                .date-item {
                    color: $secondary;
                }

                &:before {
                    background: lighten($secondary, 40%);
                }
            }
        }

        button:focus, button:active {
            background: transparent !important;
            border-color: transparent !important;
        }
    }

    .calendar-event {
        background: $muted-grey;
        padding: .6rem .4rem !important;

        &.is-primary {
            background: $primary !important;
        }

        &.is-secondary {
            background: $secondary !important;
        }

        &.is-accent {
            background: $accent !important;
        }

        &.is-success {
            background: $green !important;
        }

        &.is-warning {
            background: $orange !important;
            color: $white !important;
        }

        &.is-danger {
            background: $red !important;
        }
    }
}

.datepicker-legacy.modal, .datepicker-legacy {
    &.is-active {
        .modal-background {
            display: block !important;
            width: 100vw !important;
            height: 100vh !important;
            min-width: 100vw !important;
            min-height: 100vh !important;
            position: fixed !important;
            top: 0 !important;
            left: 0 !important;
            background: rgba(0, 0, 0, 0.8) !important;
            transform: scale(1) !important;
            border-radius: 0 !important;
        }

        .calendar-legacy {
            z-index: 99 !important;

            .calendar-nav {
                background: $secondary;

                button:focus, button:active {
                    background: transparent !important;
                    border-color: transparent !important;
                }
            }

            .calendar-body {
                min-height: 266px !important;

                .date-item {
                    &.is-active {
                        background: $secondary;
                        border-color: $secondary;
                        color: $white;
                    }
                }
            }
        }
    }
}

.datepicker-legacy {
    .calendar-legacy {
        border: 1px solid $fade-grey;
    }
}

/* ==========================================================================
1. New Calendar
========================================================================== */

.datetimepicker-wrapper {
    &.modal {
        &.is-active {
            .modal-background {
                transform: scale(1) !important;
                border-radius: 0;
                height: 100%;
                width: 100%;
                background: rgba($sidebar, 0.6);
            }

            .datetimepicker {
                z-index: 99;
            }
        }
    }

    .datetimepicker {
        margin: 0 auto;
    }
}

.datetimepicker-dummy-wrapper {
    border: 1px solid darken($fade-grey, 3%) !important;
    border-radius: 4px;
    background: $white;
    height: 38px !important;
}
