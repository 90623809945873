/*! _button-elements.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Button elements Kit SCSS file
========================================================================== */

/***
TABLE OF CONTENTS
    0. Fab Button
    1. Pricing Button
***/

/* ==========================================================================
0. Fab Button
========================================================================== */

.fab-btn {
    box-sizing: border-box;
    width: 56px;
    height: 56px;
    padding: 16px;
    border: none;
    border-radius: 50%;
    color: $white;
    transition: all 0.2s ease;
    cursor: pointer;
    z-index: 1;

    &:active, &:focus {
        outline: none !important;
    }

    &.mini {
        width: 48px;
        height: 48px;
        padding: 12px;
        transform: rotate(-600deg);
        background: $primary;
    }

    &.like {
        background: $title-grey !important;
        transform: rotate(0deg);
        position: absolute;
        right: 28%;
        bottom: -22px;
        box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);

        i.unliked {
            display: block;
            color: $white;
            position: relative;
            z-index: 1;
        }

        i.liked {
            display: none;
            color: $white;
            position: relative;
            z-index: 1;
        }

        &.is-active {
            .like-overlay {
                transform: scale(1);
            }

            i.unliked {
                display: none;
            }

            i.liked {
                display: block;
            }
        }

        &.is-single {
            bottom: -34px;
        }
    }

    .like-wrapper {
        position: relative;
        width: 100%;
        height: 100%;

        .like-overlay {
            position: absolute;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            top: -36px;
            left: -24px;
            background: $red;
            transform: scale(0);
            transition: transform 0.4s;
            z-index: 0;
        }
    }
}

/* ==========================================================================
1. Pricing Button
========================================================================== */

#pricing {
    .button-wrap {
        .combo-button {
            .button {
                &.is-active {
                    background: $primary;
                    color: $white;
                }
            }
        }
    }
}