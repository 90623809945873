/*! _responsive.scss | Bulkit | CSS Ninja */

/* ==========================================================================
General responsive styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. max-width 767px
1. max-width 768px
2. Between 768px and 1024px portrait orientation
3. max-width 1007px
4. Custom burger and navbar adjustments
=============================================================================
***/

/* ==========================================================================
0. max-width 767px
========================================================================== */

@media (max-width: 767px) {
    .content-wrapper {
        //padding-top: 40px !important;
   
    }

    .navbar {
        &.is-dark-mobile {
            .navbar-item {
                &.is-mega {
                    .is-megamenu {
                        .mega-inner {
                            .is-mega-menu-title {
                                color: $white;
                            }

                            .navbar-item {
                                &:active, &:focus {
                                    background: lighten($sidebar, 4%);
                                }
                            }
                        }
                    }
                }
            }
        }

        .navbar-item {
            &.is-mega {
                .is-megamenu {
                    max-height: 275px;
                    overflow-y: auto;
                    border-bottom: 1px solid $fade-grey;

                    .mega-inner {
                        .column:last-child {
                            display: none;
                        }

                        .navbar-item {
                            width: 49%;
                            max-width: 49%;
                            display: inline-block !important;
                        }

                        .level {
                            .level-left {
                                display: none;
                            }

                            .level-right {
                                .level-item {
                                    justify-content: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .is-flex-center-mobile {
        justify-content: center !important;
    }

    .is-centered-mobile {
        text-align: center;

        .button-wrap {
            display: flex;
            justify-content: center;
        }
    }

    form {
        button[type=submit] {
            width: 100% !important;
        }
    }
}

/* ==========================================================================
1. max-width 768px
========================================================================== */

@media (max-width: 768px) {
    .nav-item.is-tab {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .nav .nav-item.is-tab.is-active, .nav-menu.is-active .nav-item.is-tab.is-active {
        border-bottom: none !important;
        color: $primary !important;
    }

    .nav-item.nav-inner {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    a.nav-item.is-not-mobile {
        display: none !important;
    }

    .nav-item.is-menu-mobile {
        display: block !important;
        color: #7a7a7a !important;
    }

    .navbar {
        &.navbar-light {
            &.is-dark-mobile {
                background: $sidebar !important;

                .navbar-menu {
                    background: $sidebar !important;

                    a {
                        color: $white;
                    }

                    .mobile-drop {
                        &.is-active {
                            .mobile-drop-trigger {
                                opacity: .9;

                                i {
                                    transform: rotate(90deg);
                                }
                            }
                        }

                        .mobile-drop-trigger {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: $white;
                            padding: 10px;
                            transition: all .3s;

                            i {
                                position: relative;
                                top: 2px;
                                font-size: 16px;
                                margin-left: 4px;
                                transition: all .3s;
                            }
                        }

                        .child-menu {
                            display: none;
                            border-top: 1px solid $white;
                            border-bottom: 1px solid $white;

                            ul {
                                li {
                                    a {
                                        display: block;
                                        width: 100%;
                                        padding: 10px;
                                        font-size: .9rem;
                                        text-align: center;
                                        color: lighten($muted-grey, 25%);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .navbar-menu {
            .navbar-link, .navbar-item, .navbar-dropdown {
                width: 100%;
                text-align: center;
            }

            .navbar-link {
                &:after {
                    display: block !important;
                    right: 43%;
                }
            }

            .button {
                width: 100%;
            }

            .mobile-drop {
                &.is-active {
                    .mobile-drop-trigger {
                        opacity: .9;

                        i {
                            transform: rotate(90deg);
                        }
                    }
                }

                .mobile-drop-trigger {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $grey-3;
                    padding: 10px;
                    transition: all .3s;

                    i {
                        position: relative;
                        top: 2px;
                        font-size: 16px;
                        margin-left: 4px;
                        transition: all .3s;
                    }
                }

                .child-menu {
                    display: none;
                    border-top: 1px solid $fade-grey;
                    border-bottom: 1px solid $fade-grey;

                    ul {
                        li {
                            a {
                                display: block;
                                width: 100%;
                                padding: 10px;
                                font-size: .9rem;
                                text-align: center;
                                color: $muted-grey;
                            }
                        }
                    }
                }
            }
        }
    }

    .navbar-brand {
        min-height: 4.6rem;
    }

    .custom-burger {
        display: flex !important;
    }

    .navbar-burger {
        height: 4.6rem;
        width: 4.6rem;
    }

    .level-left.level-social {
        display: flex;
        justify-content: flex-start;

        .level-item:last-child .icon i {
            position: relative !important;
        }
    }

    #main-hero {
        max-height: 100vh;
        background-position-x: 55% !important;
    }

    .parallax-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0 !important;
    }

    .title.section-title {
        font-size: 2rem !important;
    }

    .hero.is-fullheight.is-halfed-mobile {
        height: 35vh !important;
    }

    .three-grid, .four-grid, .five-grid {
        .column {
            border: none !important;
        }

        .is-separator {
            border: none !important;
        }
    }

    .single-image-carousel .image-wrapper img {
        min-height: 50px !important;
    }

    .single-image-carousel .image-wrapper .caption {
        display: none;
    }

    .video-caption {
        left: 5%;
    }

    #navigation-trigger, .navigation-close {
        opacity: 1 !important;
    }

    .navbar-wrapper.navbar-fade.navbar-light.mobile-menu-dark {
        background-color: $sidebar !important;

        .nav-menu.is-active {
            background-color: $sidebar !important;

            .nav-inner.is-menu-mobile {
                color: $white !important;

                &.is-active {
                    color: $primary !important;
                }
            }
        }
    }

    .navbar-wrapper.navbar-fade.mobile-menu-dark {
        background-color: $white !important;
    }
}

/* ==========================================================================
1. Between 768px and 1024px portrait orientation
========================================================================== */

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .navbar {
        &.is-dark-mobile {
            .navbar-item {
                &.is-mega {
                    .is-megamenu {
                        .mega-inner {
                            .is-mega-menu-title {
                                color: $white;
                            }

                            .navbar-item {
                                &:active, &:focus {
                                    background: lighten($sidebar, 4%);
                                }
                            }
                        }
                    }
                }
            }
        }

        .navbar-item {
            &.is-mega {
                .is-megamenu {
                    max-height: 275px;
                    overflow-y: auto;
                    border-bottom: 1px solid $fade-grey;

                    .mega-inner {
                        .column:last-child {
                            display: none;
                        }

                        .navbar-item {
                            width: 49%;
                            max-width: 49%;
                            display: inline-block !important;
                        }

                        .level {
                            justify-content: center;

                            .level-left {
                                display: none;
                            }

                            .level-right {
                                .level-item {
                                    justify-content: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .columns {
        padding: 0 10%;

        &.is-tablet-portrait {
            padding: 10%;
        }

        &.is-not-tablet-portrait {
            padding: 0 !important;
        }
    }

    .content-wrapper {
        //padding-top: 0 !important;
   
    }

    p {
        &.is-tablet-padded {
            padding: 0 10%;
        }
    }

    .is-flex-center-mobile {
        justify-content: center !important;
    }

    .is-centered-mobile {
        text-align: center;

        .button-wrap {
            display: flex;
            justify-content: center;
        }
    }

    .is-centered-tablet-portrait {
        text-align: center;
    }

    .is-flex-portrait {
        display: flex;
    }

    #component-page {
        .hero {
            .hero-body {
                .column.is-4 {
                    text-align: center !important;
                }
            }
        }
    }
}

/* ==========================================================================
2. max-width 1007px
========================================================================== */
@media (max-width: 1007px) {
    .column.slide-description {
        > * {
            &:first-child {
                padding-top: 60px;
            }

            &:last-child {
                padding-bottom: 60px;
            }
        }
    }

    .account {
        display: none;
    }

    .media-card {
        min-height: 300px !important;
    }

    .column.is-4.media-stretch {
        width: 100% !important;

        .media-card-image {
            min-height: 150px;
        }
    }

    .mcard-controls {
        position: relative !important;
    }
}

/* ==========================================================================
4. Tablet Landscape
========================================================================== */

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .navbar-brand {
        min-height: 4.6rem;
        height: 4.6rem;
    }

    .navbar-wrapper.navbar-fade.navbar-light.mobile-menu-dark {
        background-color: $sidebar !important;

        .nav-menu.is-active {
            background-color: $sidebar !important;

            .nav-inner.is-menu-mobile {
                color: $white !important;

                &.is-active {
                    color: $primary !important;
                }
            }
        }
    }

    .navbar-wrapper.navbar-fade.mobile-menu-dark {
        background-color: $white !important;
    }

    .navbar {
        &.navbar-light {
            &.is-dark-mobile {
                background: $sidebar;

                .navbar-menu {
                    background: $sidebar;

                    a {
                        color: $white;
                    }

                    .mobile-drop {
                        &.is-active {
                            .mobile-drop-trigger {
                                opacity: .9;

                                i {
                                    transform: rotate(90deg);
                                }
                            }
                        }

                        .mobile-drop-trigger {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: $white;
                            padding: 10px;
                            transition: all .3s;

                            i {
                                position: relative;
                                top: 2px;
                                font-size: 16px;
                                margin-left: 4px;
                                transition: all .3s;
                            }
                        }

                        .child-menu {
                            display: none;
                            border-top: 1px solid $white;
                            border-bottom: 1px solid $white;

                            ul {
                                li {
                                    a {
                                        display: block;
                                        width: 100%;
                                        padding: 10px;
                                        font-size: .9rem;
                                        text-align: center;
                                        color: lighten($muted-grey, 25%);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .navbar-item.has-dropdown {
            .navbar-link {
                &:after {
                    right: 20%;
                }
            }
        }

        .navbar-menu {
            .navbar-link, .navbar-item, .navbar-dropdown {
                text-align: center;
            }

            .navbar-link {
                &:after {
                    display: block !important;
                }
            }

            .mobile-drop {
                &.is-active {
                    .mobile-drop-trigger {
                        opacity: .9;

                        i {
                            transform: rotate(90deg);
                        }
                    }
                }

                .mobile-drop-trigger {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $grey-3;
                    padding: 10px;
                    transition: all .3s;

                    i {
                        position: relative;
                        top: 2px;
                        font-size: 16px;
                        margin-left: 4px;
                        transition: all .3s;
                    }
                }

                .child-menu {
                    display: none;
                    border-top: 1px solid $fade-grey;
                    border-bottom: 1px solid $fade-grey;

                    ul {
                        li {
                            a {
                                display: block;
                                width: 100%;
                                padding: 10px;
                                font-size: .9rem;
                                text-align: center;
                                color: $muted-grey;
                            }
                        }
                    }
                }
            }

            .button {
                width: 100%;
            }

            #signup-btn, #signup-btn-alt {
                width: 30% !important;
            }
        }
    }
}

/* ==========================================================================
4. Custom burger and navbar adjustments
========================================================================== */

.navbar {
    .navbar-menu.is-active {
        .navbar-item.has-dropdown.is-hidden-mobile {
            display: none !important;
        }
    }
}

@media only screen and (max-width: 1023px) {
    .custom-burger {
        display: flex !important;
    }
}
