/*! _section-elements.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Section Elements SCSS file
========================================================================== */

/***
TABLE OF CONTENTS
    0. Phone Slider
    1. Hover Cards
    3. Pseudo Lines Sections
    4. Process Wrap
    5. Vertical Process Wrap
    6. Job results
    7. Success Stories
    8. Image Section
    9. Testimonials
    10. Process blocks section
***/

/* ==========================================================================
0. Phone Slider
========================================================================== */

body {
    &.is-theme-core {
        .phone-slide {
            background-position: center left;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: 50% 50%;
            vertical-align: top;
            padding: 800px 30px 30px 30px;

            &.is-first {
                background-image: url(../images/graphics/compositions/phone-slide-1-core.png);
            }

            &.is-second {
                background-image: url(../images/graphics/compositions/phone-slide-2-core.png);
            }

            &.is-third {
                background-image: url(../images/graphics/compositions/phone-slide-3-core.png);
            }

            &.is-fourth {
                background-image: url(../images/graphics/compositions/phone-slide-4-core.png);
            }
        }
    }

    &.is-theme-purple {
        .phone-slide {
            background-position: center left;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: 50% 50%;
            vertical-align: top;
            padding: 800px 30px 30px 30px;

            &.is-first {
                background-image: url(../images/graphics/compositions/phone-slide-1-purple.png);
            }

            &.is-second {
                background-image: url(../images/graphics/compositions/phone-slide-2-purple.png);
            }

            &.is-third {
                background-image: url(../images/graphics/compositions/phone-slide-3-purple.png);
            }

            &.is-fourth {
                background-image: url(../images/graphics/compositions/phone-slide-4-purple.png);
            }
        }
    }

    &.is-theme-teal {
        .phone-slide {
            background-position: center left;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: 50% 50%;
            vertical-align: top;
            padding: 800px 30px 30px 30px;

            &.is-first {
                background-image: url(../images/graphics/compositions/phone-slide-1-teal.png);
            }

            &.is-second {
                background-image: url(../images/graphics/compositions/phone-slide-2-teal.png);
            }

            &.is-third {
                background-image: url(../images/graphics/compositions/phone-slide-3-teal.png);
            }

            &.is-fourth {
                background-image: url(../images/graphics/compositions/phone-slide-4-teal.png);
            }
        }
    }

    &.is-theme-green {
        .phone-slide {
            background-position: center left;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: 50% 50%;
            vertical-align: top;
            padding: 800px 30px 30px 30px;

            &.is-first {
                background-image: url(../images/graphics/compositions/phone-slide-1-green.png);
            }

            &.is-second {
                background-image: url(../images/graphics/compositions/phone-slide-2-green.png);
            }

            &.is-third {
                background-image: url(../images/graphics/compositions/phone-slide-3-green.png);
            }

            &.is-fourth {
                background-image: url(../images/graphics/compositions/phone-slide-4-green.png);
            }
        }
    }

    &.is-theme-azur {
        .phone-slide {
            background-position: center left;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: 50% 50%;
            vertical-align: top;
            padding: 800px 30px 30px 30px;

            &.is-first {
                background-image: url(../images/graphics/compositions/phone-slide-1-azur.png);
            }

            &.is-second {
                background-image: url(../images/graphics/compositions/phone-slide-2-azur.png);
            }

            &.is-third {
                background-image: url(../images/graphics/compositions/phone-slide-3-azur.png);
            }

            &.is-fourth {
                background-image: url(../images/graphics/compositions/phone-slide-4-azur.png);
            }
        }
    }

    &.is-theme-blue {
        .phone-slide {
            background-position: center left;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: 50% 50%;
            vertical-align: top;
            padding: 800px 30px 30px 30px;

            &.is-first {
                background-image: url(../images/graphics/compositions/phone-slide-1-blue.png);
            }

            &.is-second {
                background-image: url(../images/graphics/compositions/phone-slide-2-blue.png);
            }

            &.is-third {
                background-image: url(../images/graphics/compositions/phone-slide-3-blue.png);
            }

            &.is-fourth {
                background-image: url(../images/graphics/compositions/phone-slide-4-blue.png);
            }
        }
    }

    &.is-theme-night {
        .phone-slide {
            background-position: center left;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: 50% 50%;
            vertical-align: top;
            padding: 800px 30px 30px 30px;

            &.is-first {
                background-image: url(../images/graphics/compositions/phone-slide-1-night.png);
            }

            &.is-second {
                background-image: url(../images/graphics/compositions/phone-slide-2-night.png);
            }

            &.is-third {
                background-image: url(../images/graphics/compositions/phone-slide-3-night.png);
            }

            &.is-fourth {
                background-image: url(../images/graphics/compositions/phone-slide-4-night.png);
            }
        }
    }

    &.is-theme-yellow {
        .phone-slide {
            background-position: center left;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: 50% 50%;
            vertical-align: top;
            padding: 800px 30px 30px 30px;

            &.is-first {
                background-image: url(../images/graphics/compositions/phone-slide-1-yellow.png);
            }

            &.is-second {
                background-image: url(../images/graphics/compositions/phone-slide-2-yellow.png);
            }

            &.is-third {
                background-image: url(../images/graphics/compositions/phone-slide-3-yellow.png);
            }

            &.is-fourth {
                background-image: url(../images/graphics/compositions/phone-slide-4-yellow.png);
            }
        }
    }

    &.is-theme-orange {
        .phone-slide {
            background-position: center left;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: 50% 50%;
            vertical-align: top;
            padding: 800px 30px 30px 30px;

            &.is-first {
                background-image: url(../images/graphics/compositions/phone-slide-1-orange.png);
            }

            &.is-second {
                background-image: url(../images/graphics/compositions/phone-slide-2-orange.png);
            }

            &.is-third {
                background-image: url(../images/graphics/compositions/phone-slide-3-orange.png);
            }

            &.is-fourth {
                background-image: url(../images/graphics/compositions/phone-slide-4-orange.png);
            }
        }
    }

    &.is-theme-red {
        .phone-slide {
            background-position: center left;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: 50% 50%;
            vertical-align: top;
            padding: 800px 30px 30px 30px;

            &.is-first {
                background-image: url(../images/graphics/compositions/phone-slide-1-red.png);
            }

            &.is-second {
                background-image: url(../images/graphics/compositions/phone-slide-2-red.png);
            }

            &.is-third {
                background-image: url(../images/graphics/compositions/phone-slide-3-red.png);
            }

            &.is-fourth {
                background-image: url(../images/graphics/compositions/phone-slide-4-red.png);
            }
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .column {
        &.slide-description {
            padding: 40px 0 !important;
        }
    }
}


/* ==========================================================================
1. Hover Cards
========================================================================== */

.hover-cards {
    .icon-card-hover {
        height: 170px;
        padding: 20px 20px 10px 130px;
        border-radius: 8px;
        box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.04);
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
        text-align: left;
        background-color: $white;

        &:hover {
            box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.1);
            -webkit-transform: translate(0, -10px);
            -ms-transform: translate(0, -10px);
            transform: translate(0, -10px);
        }

        &.first-card {
            background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-1-core.svg);
            background-position: 0 0,-80px 20px;
            background-size: auto,200px;
            background-repeat: repeat,no-repeat;
            -webkit-transition: all 350ms ease;
            transition: all 350ms ease;

            &:hover {
                background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-1-core.svg);
                background-position: 0 0,-80px -25px;
                background-size: auto,200px;
                background-repeat: repeat,no-repeat;
            }
        }

        &.second-card {
            background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-2-core.svg);
            background-position: 0 0,-80px 20px;
            background-size: auto,200px;
            background-repeat: repeat,no-repeat;
            -webkit-transition: all 350ms ease;
            transition: all 350ms ease;

            &:hover {
                background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-2-core.svg);
                background-position: 0 0,-80px -25px;
                background-size: auto,200px;
                background-repeat: repeat,no-repeat;
            }
        }

        &.third-card {
            background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-3-core.svg);
            background-position: 0 0,-80px 20px;
            background-size: auto,200px;
            background-repeat: repeat,no-repeat;
            -webkit-transition: all 350ms ease;
            transition: all 350ms ease;

            &:hover {
                background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-3-core.svg);
                background-position: 0 0,-80px -25px;
                background-size: auto,200px;
                background-repeat: repeat,no-repeat;
            }
        }

        &.fourth-card {
            background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-4-core.svg);
            background-position: 0 0,-80px 20px;
            background-size: auto,200px;
            background-repeat: repeat,no-repeat;
            -webkit-transition: all 350ms ease;
            transition: all 350ms ease;

            &:hover {
                background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-4-core.svg);
                background-position: 0 0,-80px -25px;
                background-size: auto,200px;
                background-repeat: repeat,no-repeat;
            }
        }
    }

    .button-wrap {
        padding: 40px 0;
    }
}

body {
    &.is-theme-core {
        .hover-cards {
            .icon-card-hover {
                &.first-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-1-core.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-1-core.svg);
                    }
                }

                &.second-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-2-core.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-2-core.svg);
                    }
                }

                &.third-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-3-core.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-3-core.svg);
                    }
                }

                &.fourth-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-4-core.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-4-core.svg);
                    }
                }
            }
        }
    }

    &.is-theme-purple {
        .hover-cards {
            .icon-card-hover {
                &.first-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-1-purple.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-1-purple.svg);
                    }
                }

                &.second-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-2-purple.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-2-purple.svg);
                    }
                }

                &.third-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-3-purple.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-3-purple.svg);
                    }
                }

                &.fourth-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-4-purple.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-4-purple.svg);
                    }
                }
            }
        }
    }

    &.is-theme-teal {
        .hover-cards {
            .icon-card-hover {
                &.first-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-1-teal.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-1-teal.svg);
                    }
                }

                &.second-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-2-teal.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-2-teal.svg);
                    }
                }

                &.third-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-3-teal.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-3-teal.svg);
                    }
                }

                &.fourth-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-4-teal.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-4-teal.svg);
                    }
                }
            }
        }
    }

    &.is-theme-green {
        .hover-cards {
            .icon-card-hover {
                &.first-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-1-green.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-1-green.svg);
                    }
                }

                &.second-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-2-green.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-2-green.svg);
                    }
                }

                &.third-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-3-green.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-3-green.svg);
                    }
                }

                &.fourth-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-4-green.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-4-green.svg);
                    }
                }
            }
        }
    }

    &.is-theme-azur {
        .hover-cards {
            .icon-card-hover {
                &.first-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-1-azur.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-1-azur.svg);
                    }
                }

                &.second-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-2-azur.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-2-azur.svg);
                    }
                }

                &.third-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-3-azur.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-3-azur.svg);
                    }
                }

                &.fourth-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-4-azur.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-4-azur.svg);
                    }
                }
            }
        }
    }

    &.is-theme-blue {
        .hover-cards {
            .icon-card-hover {
                &.first-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-1-blue.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-1-blue.svg);
                    }
                }

                &.second-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-2-blue.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-2-blue.svg);
                    }
                }

                &.third-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-3-blue.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-3-blue.svg);
                    }
                }

                &.fourth-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-4-blue.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-4-blue.svg);
                    }
                }
            }
        }
    }

    &.is-theme-night {
        .hover-cards {
            .icon-card-hover {
                &.first-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-1-night.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-1-night.svg);
                    }
                }

                &.second-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-2-night.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-2-night.svg);
                    }
                }

                &.third-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-3-night.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-3-night.svg);
                    }
                }

                &.fourth-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-4-night.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-4-night.svg);
                    }
                }
            }
        }
    }

    &.is-theme-yellow {
        .hover-cards {
            .icon-card-hover {
                &.first-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-1-yellow.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-1-yellow.svg);
                    }
                }

                &.second-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-2-yellow.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-2-yellow.svg);
                    }
                }

                &.third-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-3-yellow.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-3-yellow.svg);
                    }
                }

                &.fourth-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-4-yellow.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-4-yellow.svg);
                    }
                }
            }
        }
    }

    &.is-theme-orange {
        .hover-cards {
            .icon-card-hover {
                &.first-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-1-orange.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-1-orange.svg);
                    }
                }

                &.second-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-2-orange.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-2-orange.svg);
                    }
                }

                &.third-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-3-orange.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-3-orange.svg);
                    }
                }

                &.fourth-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-4-orange.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-4-orange.svg);
                    }
                }
            }
        }
    }

    &.is-theme-red {
        .hover-cards {
            .icon-card-hover {
                &.first-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-1-red.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-1-red.svg);
                    }
                }

                &.second-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-2-red.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-2-red.svg);
                    }
                }

                &.third-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-3-red.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-3-red.svg);
                    }
                }

                &.fourth-card {
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), url(../images/graphics/icons/motion-icon-4-red.svg);

                    &:hover {
                        background-image: linear-gradient(180deg, transparent, transparent), url(../images/graphics/icons/motion-icon-4-red.svg);
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .hover-cards {
        .icon-card-hover {
            padding: 20px 20px 10px 100px !important;

            .card-description {
                font-size: 14px;
            }
        }
    }
}

/* ==========================================================================
3. Pseudo Lines Sections
========================================================================== */

.section {
    position: relative;

    &.gradient-circle {
        background: url(../images/graphics/legacy/gradient-circle-alt.png) repeat top right, #fff;
    }

    &.has-pseudo-lines {
        &:before {
            content: '';
            width: 2px;
            height: 60px;
            background: $primary;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: rotate(180deg);
        }

        &:after {
            content: '';
            width: 2px;
            height: 60px;
            background: $primary;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: rotate(180deg);
        }
    }

    &.no-line-top {
        &:before {
            display: none !important;
        }
    }

    &.no-line-bottom {
        &:after {
            display: none !important;
        }
    }
}

/* ==========================================================================
4. Process Wrap
========================================================================== */

.process-wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    .process-block {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 20%;
            right: 0;
            height: 2px;
            width: 50%;
            background: #e0ecff;
        }

        &:before {
            content: '';
            position: absolute;
            top: 20%;
            left: 0;
            height: 2px;
            width: 50%;
            background: #e0ecff;
        }

        &:first-child {
            &:before {
                display: none;
            }
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        .process-icon {
            position: relative;
            height: 70px;
            width: 70px;
            border-radius: 50%;
            background: #e0ecff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            z-index: 2;

            img {
                height: 40px;
                width: 40px;
                display: block;
            }
        }

        .text-block {
            padding: 16px;

            p {
                margin-top: 2px;
                padding: 0 16px;
                color: $muted-grey;
            }
        }
    }
}

@media (max-width: 767px) {
    .process-wrap {
        display: block;

        .process-block {
            margin-bottom: 20px;

            &:before, &:after {
                display: block !important;
            }
        }
    }
}

/* ==========================================================================
5. Vertical Process Wrap
========================================================================== */

.vertical-process {
    .vertical-block {
        position: relative;
        display: flex;
        align-items: flex-start;
        margin-bottom: 40px;

        &:before {
            position: absolute;
            left: -24px;
            bottom: 3px;
            content: '';
            height: 1px;
            width: 88px;
            background: $placeholder;
            transform: rotate(90deg) !important;
        }

        &:last-child {
            &:before {
                display: none;
            }
        }

        &:hover {
            .process-icon {
                background: $secondary;
                border-color: $secondary;

                i {
                    color: $smoke-white;
                }
            }

            .vertical-text {
                h4 {
                    color: $secondary;
                }
            }
        }

        .process-icon {
            position: relative;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 40px;
            min-width: 40px;
            border-radius: 50%;
            border: 1px solid $placeholder;
            transition: all .3s;

            i {
                color: $secondary;
                font-size: 22px;
                font-weight: 600;
                transition: all .3s;
            }
        }

        .vertical-text {
            margin: 0 20px;

            h4 {
                font-family: $font-main;
                font-size: 1.2rem;
                font-weight: 500;
                margin-bottom: 6px;
                transition: all .3s;
            }

            p {
                font-size: .95rem;
                color: $muted-grey;
            }
        }
    }
}

/* ==========================================================================
6. Job results
========================================================================== */

.job-results-wrapper {
    padding: 40px 0;

    .job-results-filter {
        .checkbox-list label {
            padding: 0 !important;
        }
    }

    .job-results {
        .job-results-section {
            padding-bottom: 60px;

            h3 {
                margin-bottom: 30px;
                padding: 0 16px;
            }

            .result-list {
                .result-list-item {
                    display: flex;
                    align-items: center;
                    padding: 16px;
                    border-radius: 10px;
                    transition: all .3s;
                    cursor: pointer;

                    &:hover {
                        background: lighten(#f2f2fa, 1%);

                        img {
                            filter: grayscale(0);
                            opacity: 1;
                        }

                        .end {
                            .tag:not(.is-secondary) {
                                background: $white;
                            }
                        }
                    }

                    img {
                        display: block;
                        height: 48px;
                        width: 48px;
                        border-radius: 50%;
                        filter: grayscale(1);
                        opacity: .6;
                        transition: all .3s;
                    }

                    .meta {
                        margin-left: 12px;

                        > span {
                            font-size: 1.2rem;
                            font-weight: 500;
                            color: $blue-grey;
                        }

                        .details {
                            span {
                                color: $muted-grey;

                                &.spacer {
                                    padding: 0 10px;
                                    opacity: .6;
                                }
                            }
                        }
                    }

                    .end {
                        margin-left: auto;

                        .tag {
                            transition: all .3s;
                        }

                        .time {
                            display: block;
                            font-size: .95rem;
                            color: $muted-grey;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .job-results-wrapper {
        padding-top: 0;
        padding-bottom: 0;

        .job-results-section {
            h3 {
                text-align: center;
            }

            .result-list {
                .result-list-item {
                    flex-direction: column;
                    text-align: center;
                    border: 1px solid $fade-grey;
                    border-radius: 8px;
                    margin-bottom: 20px;

                    .end {
                        margin: 10px auto 0 auto;
                    }
                }
            }
        }
    }
}

/* ==========================================================================
7. Success Stories
========================================================================== */

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .stories {
        display: flex;

        .column {
            min-width: 50%;
        }
    }
}

/* ==========================================================================
8. Image Section
========================================================================== */

.section-wrap {
    display: flex;
    align-items: stretch;
    min-height: 650px;

    .section {
        &.image-section {
            width: 50%;
            padding-left: 10%;
            padding-right: 5%;
            background: darken($sidebar, 8%);

            .title, .subtitle, p {
                color: $white;
            }

            .support-features {
                padding: 16px 0;

                > p {
                    margin-bottom: 16px;
                }

                .support-card {
                    display: inline-block;
                    padding: 20px;
                    width: 100%;
                    border-radius: 10px;
                    background: darken($sidebar, 14%);
                    border: 1px solid darken($sidebar, 10%);
                    text-align: center;
                    transition: all .3s;

                    &:hover {
                        border-color: $primary !important;
                        box-shadow: $light-box-shadow;

                        h3, i {
                            color: $primary;
                        }
                    }

                    i {
                        font-size: 2.8rem;
                        color: $muted-grey;
                        transition: color .3s;
                    }

                    h3 {
                        font-weight: 500;
                        color: $white;
                        transition: color .3s;
                    }

                    p {
                        font-size: .95rem;
                        color: $muted-grey;
                    }
                }
            }
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

@media (max-width: 767px) {
    .section-wrap {
        flex-direction: column;

        .section {
            &.image-section {
                width: 100%;
                padding-left: 10%;
                padding-right: 10%;
                text-align: center;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .section-wrap {
        .section {
            &.image-section {
                padding-left: 5%;
                padding-right: 5%;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .section-wrap {
        .section {
            &.image-section {
                padding-left: 5%;
                padding-right: 5%;
            }
        }
    }
}

/* ==========================================================================
9. Testimonials
========================================================================== */

.flat-testimonial-item {
    .company {
        max-width: 100px;
        margin-right: 0 !important;
    }
}

/* ==========================================================================
10. Process blocks section
========================================================================== */
.process-block {
    position: relative;
    text-align: center;

    .process-icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 5rem;

        .icon-wrapper {
            position: relative;
            width: 180px;
            height: 180px;
            border-radius: 50%;
            background: $white;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &.has-line:after {
        content: '';
        height: 4px;
        width: 80px;
        background: $fade-grey;
        position: absolute;
        top: 88px;
        right: -14%;
        border-radius: 50px;
    }

    .process-number {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        font-size: 14px;
        font-weight: bolder;
        position: absolute;
        top: 13px;
        right: 0;
        background: $primary;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .process-info {
        display: flex;
        justify-content: center;
        align-items: center;

        .step-number {
            font-size: 8rem;
            font-weight: bolder;
            color: $fade-grey;
            margin-right: 15px;
        }

        .details {
            text-align: left;
        }

        .motto {
            font-size: 2.4rem;
            font-weight: 700;
            color: $blue-grey;
        }

        .description {
            font-size: 95%;
            color: $muted-grey;
        }
    }
}

@media (max-width: 768px) {
    .process-block {
        &.has-line:after {
            display: none !important;
        }
    }
}
