/*! custom.scss | Bulkit | CSS weloobe */

@import "../../sass/sass/utilities/_all.sass";

/* ==========================================================================
 section
========================================================================== */
#dev-hero {
    background: $widget-grey url(../images/graphics/legacy/icon-bg-dark.png) 0 0;
    -webkit-animation: 18s linear 0s normal none infinite animate;
    -moz-animation: 18s linear 0s normal none infinite animate;
    -ms-animation: 18s linear 0s normal none infinite animate;
    -o-animation: 18s linear 0s normal none infinite animate;
    animation: 18s linear 0s normal none infinite animate;
}

#hard-hero {
    background: $widget-grey url(../images/graphics/legacy/icon-bg-h-dark.png) 0 0;
    -webkit-animation: 18s linear 0s normal none infinite animate;
    -moz-animation: 18s linear 0s normal none infinite animate;
    -ms-animation: 18s linear 0s normal none infinite animate;
    -o-animation: 18s linear 0s normal none infinite animate;
    animation: 18s linear 0s normal none infinite animate;
}

.slick-list {
    min-height: 23em;
}

/* ==========================================================================
 footer
========================================================================== */

footer {
    &.footer-dark, &.footer-light {
        .footer-logo {
            img {
                height: 56px;
            }
        }
    }

    &.has-animated-waves {
        z-index: 1;
    }
}

.hero-foot {
    img.partner-logo {
        height: 38px !important;
    }
}

/* ==========================================================================
 Navbar
========================================================================== */

.navbar-wrapper {
    .navbar-brand, &.navbar-faded .navbar-brand {
        img {
            height: 48px;
        }
    }
}

.mega-inner {
    .navbar-item {
        &:hover {
            img.w-filter {
                filter: drop-shadow(0px 0px 5px #962bfe);
            }
        }

        .item-title {
            color: $primary;
        }
    }
}

.is-dark-mobile:not(.navbar-faded) {
    .mega-inner {
        .navbar-item {
            .item-title {
                color: $white;
                text-shadow: 0 0 5px $white;
            }
        }
    }
}

.navbar-dropdown {
    .navbar-item {
        @media screen and (min-width: 1024px) {
            & {
                white-space: inherit;
            }
        }
    }
}

.navbar-item {
    img {
        max-height: 3.5rem;
    }
}

/* ==========================================================================
 Megamenu
========================================================================== */

.is-megamenu {
    .mega-inner {
        .navbar-item {
            &.is-flex {
                &:hover {
                    i {
                        color: $primary;
                    }
                }
            }
        }
    }
}

/* ==========================================================================
 Base modal class
========================================================================== */
.modal {
    &.is-active {    
        //modal overlay
        .modal-background {
            opacity: 0.77;
        }
    }
    .modal-close {
        &:hover {
            transform: rotate(180deg);
        }
    }
}

/* ==========================================================================
 Theme custom Helpers
========================================================================== */
.landing-logo {
    width: 3em;
}

.affiliate-logo {
    width: 9em;
}

.control-material {
    label, span {
        &.error {
            top: auto !important;
            font-size: 12px;
            color: $red;
        }
    }
}

.text-through {
    text-decoration: line-through;
}

.has-pop {
    text-decoration: underline;
    cursor: pointer;
}

.hero {
    &.is-primary-header {
        height: 9rem;
        background: $primary;
        background: linear-gradient(-45deg, $accent, $primary, $primary, $accent);
    }

    .hero-body {
        .is-header-caption {
            padding: 77px 0;
        }
    } 
}

@media only screen and (max-width: $tablet) {
    .mt-mobile-h {
        margin-top: 5em;
    }

    .m-mobile-s {
        padding: 5rem 1.5rem;
    }

    .rounded-hero .hero-body .hero-mockup {
        max-width: 320px!important;
    }

    .auth-outer .auth-box {
        max-width: 320px;
    }
}

/* ==========================================================================
 Theme specific Pages
========================================================================== */
.pricing-wrapper {
    .pricing-container {
        .container-inner {
            .plan-focus .left-side, .solo-plans .solo-plan {
                .price .amount.cfa {
                    &:before {
                        content: 'FCFA';
                    }
                } 
            } 
        }
    }
}
