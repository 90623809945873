/*! _material-shadows.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Miscellaneous styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Material design box shadow helpers
=============================================================================
***/


/* ==========================================================================
0. Material design box shadow helpers
========================================================================== */

.shadow-z-1 {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
}

.shadow-z-2 {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.shadow-z-3 {
    box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.3), 0 7px 7px 0 rgba(0, 0, 0, 0.19);
}

.shadow-z-4 {
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.3), 0 14px 12px 0 rgba(0, 0, 0, 0.17);
}

.shadow-z-5 {
    box-shadow: 0 27px 55px 0 rgba(0, 0, 0, 0.3), 0 17px 17px 0 rgba(0, 0, 0, 0.15);
}
