/*! _faq-elements.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Hero Elements SCSS file
========================================================================== */

/***
TABLE OF CONTENTS
    0. Help Center
    1. Help Center Category
    2. Help Center article
    3. FAQ v1
***/


/* ==========================================================================
0. Help Center
========================================================================== */

.help-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
        max-height: 45px;
        max-width: 45px;
        padding-right: 20px;
        border-right: 1px solid $white;
    }

    .title {
        color: $white;
        padding-left: 20px;
        position: relative;
        top: -2px;
    }
}

.help-subheader {
    padding: 20px 0 30px 0;
}

.help-search-wrapper {
    position: relative;
    width: 100%;

    input {
        width: 100%;
        padding: 20px 32px 21px 59px;
        background: rgba(255, 255, 255, 0.2);
        border: none;
        outline: none;
        color: rgba(255, 255, 255, 0.7);
        font-size: 18px;
        border-radius: 4px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
        -webkit-transition: background .4s, box-shadow .2s;
        transition: background .4s, box-shadow .2s;

        &::-webkit-input-placeholder {
            color: rgba(255, 255, 255, 0.7) !important;
        }

        &::-moz-placeholder {
            color: rgba(255, 255, 255, 0.7) !important;
        }

        &:-ms-input-placeholder {
            color: rgba(255, 255, 255, 0.7) !important;
        }

        &:-moz-placeholder {
            color: rgba(255, 255, 255, 0.7) !important;
        }

        &:focus {
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.14);
            background: $white;
            color: $blue-grey;

            + i {
                color: $muted-grey;
            }

            &::-webkit-input-placeholder {
                color: $placeholder !important;
            }

            &::-moz-placeholder {
                color: $placeholder !important;
            }

            &:-ms-input-placeholder {
                color: $placeholder !important;
            }

            &:-moz-placeholder {
                color: $placeholder !important;
            }
        }
    }

    i {
        font-size: 2rem;
        color: rgba(255, 255, 255, 0.7);
        position: absolute;
        top: 18px;
        left: 18px;
    }
}

.section.help-section {
    background: $background-grey;

    .category-card {
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);

        .card-body {
            a {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .icon {
                    width: 110px;
                    height: 110px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        font-size: 3.5rem;
                        color: $icon-grey !important;
                    }
                }

                .inner-content {
                    padding-left: 25px;

                    .title {
                        margin-bottom: 10px !important;
                        font-weight: 400;
                        color: $primary;
                    }

                    .inner-text {
                        color: $muted-grey !important;
                    }

                    .card-meta {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-top: 12px;

                        .small-avatar {
                            height: 34px;
                            width: 34px;
                            border-radius: 50%;
                            margin-right: 10px;
                            border: 2px solid $white;
                            position: relative;

                            &:not(:first-child) {
                                margin-left: -19px;
                            }

                            &:first-child {
                                z-index: 3;
                            }

                            &:nth-child(2) {
                                z-index: 2;
                            }

                            &:nth-child(3) {
                                z-index: 1;
                            }

                            &:nth-child(4) {
                                z-index: 0;
                            }

                            &.is-more {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: $white;
                                background: $muted-grey;
                                font-weight: 500;
                                font-size: 13px;

                                span {
                                    position: relative;
                                    top: -2px;
                                    left: -1px;
                                }
                            }
                        }

                        .meta-info {
                            color: $muted-grey !important;

                            .articles-number {
                                font-size: 13px;
                            }

                            .authors {
                                font-size: 13px;

                                span {
                                    color: $blue;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.breadcrumbs {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    color: $muted-grey;

    ul {
        margin: 0 !important;

        li {
            display: inline-block;
            list-style: none;
            margin: 0 0 0 10px;

            &:first-child:before {
                content: '';
                margin-left: -20px;
            }

            &:before {
                content: "\f105";
                font-family: "FontAwesome";
                color: #a0a0a0;
                font-size: 14px;
                margin-right: 15px;
            }
        }
    }

    a {
        font-weight: 500;
        color: $primary;
    }
}


/* ==========================================================================
1. Help Center Category
========================================================================== */

.help-container {
    position: relative;

    .shadow-bg {
        width: 100%;

        &:before {
            content: ' ';
            position: absolute;
            display: block;
            top: 50px;
            left: 0;
            bottom: 0;
            right: 0;
            background: #ebeef1;
            border-radius: 4px;
            -webkit-transition: background .3s;
            transition: background .3s;
            z-index: 0;
            margin: 0 10px;
        }
    }

    .category-header {
        padding: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        top: 20px;
        z-index: 2;

        .icon {
            width: 110px;
            height: 110px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 3.5rem;
                color: $icon-grey !important;
            }
        }

        .inner-content {
            padding-left: 25px;

            .title {
                margin-bottom: 10px !important;
                font-weight: 400;
                color: $primary;
            }

            .inner-text {
                color: $muted-grey !important;
            }

            .card-meta {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-top: 12px;

                .small-avatar {
                    height: 34px;
                    width: 34px;
                    border-radius: 50%;
                    margin-right: 10px;
                    border: 2px solid $white;

                    &:not(:first-child) {
                        margin-left: -19px;
                    }

                    &:first-child {
                        z-index: 3;
                    }

                    &:nth-child(2) {
                        z-index: 2;
                    }

                    &:nth-child(3) {
                        z-index: 1;
                    }

                    &:nth-child(4) {
                        z-index: 0;
                    }

                    &.is-more {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $white;
                        background: $muted-grey;
                        font-weight: 500;
                        font-size: 13px;

                        span {
                            position: relative;
                            top: -2px;
                            left: -1px;
                        }
                    }
                }

                .meta-info {
                    color: $muted-grey !important;

                    .articles-number {
                        font-size: 13px;
                    }

                    .authors {
                        font-size: 13px;

                        span {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }

    .articles-list {
        position: relative;
        margin: 30px;
        background: $white;
        width: calc(100% - 60px);
        border-radius: 4px;
        border: 1px solid $border-grey;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);

        .help-article {
            padding: 30px;

            a {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .inner-content {
                    .title {
                        margin-bottom: 10px !important;
                        font-weight: 400;
                        color: $primary;
                    }

                    .inner-text {
                        color: $muted-grey !important;
                    }

                    .card-meta {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-top: 12px;

                        .small-avatar {
                            height: 34px;
                            width: 34px;
                            border-radius: 50%;
                            margin-right: 10px;
                            border: 2px solid $white;
                            position: relative;
                        }

                        .meta-info {
                            color: $muted-grey !important;
                            line-height: 1.1rem;

                            .author {
                                font-size: 13px;

                                span {
                                    color: $blue;
                                }
                            }

                            .status {
                                font-size: 12px;
                                color: $title-grey;
                            }
                        }
                    }
                }
            }

            &:not(:last-child) {
                border-bottom: 1px solid $border-grey;
            }
        }
    }
}

/* ==========================================================================
2. Help Center article
========================================================================== */

.single-help-article {
    border-radius: 4px;
    border: 1px solid $border-grey;
    margin-top: 20px;
    padding: 60px;

    .article-inner {
        max-width: 640px;
        margin: 0 auto;

        h2 {
            font-weight: 500;
        }

        h3 {
            padding-top: 20px;
            color: $muted-grey;
            font-weight: 500;
            margin-bottom: 1rem;
        }

        .article-meta {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: $muted-grey !important;
            line-height: 1.1rem;

            .small-avatar {
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }

            .meta-info {
                color: $muted-grey !important;
                line-height: 1.1rem;
                margin-left: 15px;

                .author {
                    font-size: 13px;

                    span {
                        color: $blue;
                    }
                }

                .status {
                    font-size: 12px;
                    color: $title-grey;
                }
            }
        }

        .content {
            padding-top: 30px;

            p {
                font-size: 16px;
                color: $blue-grey;
            }

            h4 {
                padding-top: 10px;
                font-weight: 500;
            }

            ul {
                font-size: 16px;
            }
        }

        .rating-section {
            width: 100%;
            text-align: center;

            .question {
                padding-top: 25px;
                font-size: 1.1rem;
                font-weight: 500;
                color: $title-grey;
            }

            .rating-buttons {
                position: relative;
                top: 18px;
            }

            .rating-button {
                max-width: 60px;
                max-height: 60px;
                cursor: pointer;

                i {
                    font-size: 50px;
                    font-weight: 200;
                    color: $title-grey;
                    transition: all 0.3s;
                }

                &:hover i {
                    color: $primary;
                    transform: scale(1.1);
                }
            }
        }
    }
}

/* ==========================================================================
3. FAQ v1
================ ========================================================== */

.faq-wrapper {
    padding: 50px 0;

    .faq-block {
        margin: 0 0 50px 0;
        padding: 0 16px;

        &:last-child {
            margin-bottom: 0;
        }

        .block-header {
            display: flex;
            cursor: pointer;

            &.is-active {
                .header-icon {
                    transform: rotate(45deg);
                }
            }

            .header-icon {
                height: 24px;
                width: 24px;
                min-width: 24px;
                background: $primary;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all .4s;

                i {
                    color: $smoke-white;
                    font-size: 12px;
                }
            }

            h3 {
                font-size: 1.2rem;
                font-weight: 500;
                color: $blue-grey;
                margin-left: 12px;
            }
        }

        .block-body {
            display: none;
            padding-top: 16px;

            p {
                color: lighten($blue-grey, 15%);
            }
        }
    }
}

@media (max-width: 767px) {
    .faq-wrapper {
        .faq-block {
            margin: 25px 0;
        }
    }
}